<template>
    <div style="position: relative;	background-color: white;" class="box1">
        <div class="top">
            <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo">

            <div class="title-2 flex-column-center">
                <p>Simple One</p>
                <p> Simple Health</p>
            </div>

        </div>
        <div class="bottom">
            <div class="title">
                <p>你好，我是你的营养顾问</p>
                <p>请长按二维码和我联系吧</p>
            </div>
            <div class="info">
                <p style="font-weight: 700">{{ data.name }}</p>
            </div>
            <div class="flex-center">
                <van-image width="144" :src="data.qrcode" @load="createPoster"/>
            </div>

        </div>
        <img :src="url" class="poster">
    </div>
</template>

<script>
    import html2canvas from "html2canvas"
    import {Toast} from "vant";

    export default {
        name: "",
        created() {
            let id = this.$route.query.adviser_id
            this.$request.get(
                "get_adviser_qrcode", {
                    adviser_id: id || 0
                }
            ).then(res => {
                if (res.code == 1) {
                    this.data = res.data
                } else {
                    Toast(res.message)
                }
            })
        },
        data() {
            return {
                data: {},
                url: '',
            }
        },
        methods: {
            createPoster() {
                setTimeout(() => {
                    new html2canvas(document.querySelector(".box1"), {
                        allowTaint: false,
                        useCORS: true,
                        backgroundColor: null,
                        // scale:2,
                        dpi: 300
                    }).then(canvas => {
                        this.url = canvas.toDataURL("image/png")
                    })
                }, 200)
            }
        }

    }
</script>

<style scoped lang="less">
    .top {
        width: 100%;
        height: 30vh;
        padding: 20px 10px;
        box-sizing: border-box;
        background-image: url("https://khome2.tuzuu.com/vita/consul.png");
        background-size: 132% 138%;
        background-position: 50% 50%;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

        .title-2 {
            width: 100%;
            font-size: 24px;
            color: #FFFFFF;
            font-weight: 900;
            margin-top: 6vh;
            line-height: 30px;
            letter-spacing: 1px;
        }
    }

    .bottom {
        width: 100%;
        height: calc(100vh - 30vh);
        padding: 10px 0px;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .title {
            font-size: 20px;
            font-weight: 900;
            text-align: center;
            line-height: 34px;
            color: #292C2F;
            margin-bottom: 40px;
        }

        .info {
            font-size: 18px;

            color: #292C2F;
            line-height: 28px;
            text-align: center;
            margin-bottom: 20px;
        }

        .end-info {
            font-size: 14px;
            color: #666666;
            font-family: HYQiHei-55S;
            position: absolute;
            bottom: 25px;
            left: 23px;
            line-height: 20px;
            right: 23px;
        }
    }

    .poster {
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: 2;
		width: 100%;
		height: 100%;
    }
</style>